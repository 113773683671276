import { UpgradeBlockerButton } from "src/components/FeatureBlockers/UpgradeBlockerButton"
import { UpgradeComponentPopover } from "src/components/FeatureBlockers/UpgradeComponentPopover"
import { TPlanUpgradeClickedEventProperties } from "src/data/analytics/queries/planUpgradeAnalyticsQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { TPlan } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"

type UpgradeComponentPopoverProps = {
  children: React.ReactNode
  requiredPlan?: TPlan
  eventProperties: TPlanUpgradeClickedEventProperties
  description?: React.ReactNode
  hidden?: boolean
  showUpgradeBlocker?: boolean
}
export function UpgradeComponentBlocker({
  eventProperties,
  requiredPlan,
  description,
  showUpgradeBlocker,
  children,
}: UpgradeComponentPopoverProps) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const isOrgOwner = getAccessLogic({ role: org.user_role }).hasOwnerAccess
  const error = !isOrgOwner
    ? t(langKeys.subscription_upgrade_blocker_title_non_owner)
    : null

  return (
    <UpgradeComponentPopover
      text={t(langKeys.subscription_upgrade_blocker_title)}
      description={description}
      showUpgradeBlocker={showUpgradeBlocker}
      ctaButton={
        <UpgradeBlockerButton
          size="small"
          eventProperties={eventProperties}
          disabled={!isOrgOwner}
          plan={requiredPlan}
        />
      }
      errorMsg={error}
    >
      {children}
    </UpgradeComponentPopover>
  )
}
